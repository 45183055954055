import { Link } from "@remix-run/react";
import type { ComponentPropsWithoutRef } from "react";
import { RiLinkedinBoxFill, RiMailLine, RiPhoneLine, RiYoutubeFill } from "react-icons/ri";
import type { Category } from "~types/api/category.types";
import type { Contact } from "~types/api/contact.types";

import logoImg from "~/assets/images/logo.png";
import { Image } from "~/components/ui/image";
import { PageContainer } from "~/components/ui/layout/page-container";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { Text } from "~/components/ui/typography";
import { cn } from "~/utils/classnames";

interface FooterProps extends ComponentPropsWithoutRef<"footer"> {
  categories: Category[] | null;
  contact: Contact | null;
}

export function Footer({ categories, contact, className, ...props }: FooterProps) {
  return (
    <footer className={cn("bg-grey-100", className)} {...props}>
      <PageContainer>
        <Wrapper className="grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2 lg:col-span-1">
            <Link to="/">
              <Image src={logoImg} alt="Logo Powr Connect" className="max-h-10" />
            </Link>
            <Text as="p" size="sm" className="mt-2">
              POwR CONNECT est le n°1 de la distribution de solutions photovoltaïques pour les
              professionnels.
            </Text>

            <Text size="lg" className="mt-4 font-semibold">
              Nous contacter
            </Text>
            <Text as="p" muted size="xs">
              Pas besoin d&apos;un signal dans le ciel pour ça.
            </Text>
            <div className="mt-2 flex items-center gap-3 text-sm">
              <RiPhoneLine className="text-lg" />
              <a href="tel:0228443121" className="hover:underline">
                +33 2 28 44 31 21
              </a>
            </div>
            <div className="mt-1 flex items-center gap-3 text-sm">
              <RiMailLine className="text-lg" />
              <a href="mailto:contact@powr-connect.shop" className="hover:underline">
                contact@powr-connect.shop
              </a>
            </div>
            <Text as="p" muted size="xs" className="mt-2">
              A votre écoute du lundi au vendredi de 8h30 à 12h30 et de 14h à 18h.
            </Text>

            <Text size="lg" className="mt-4 font-semibold">
              Suivez-nous !
            </Text>
            <div className="mt-1 flex items-center gap-4 text-2xl">
              <Link
                to="https://www.linkedin.com/company/powr-group"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">LinkedIn</span>
                <RiLinkedinBoxFill className="text-blue-500 hover:text-blue-600" />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCAtyDVR_xn5Wo5_fTsf8Kww"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">YouTube</span>
                <RiYoutubeFill className="text-red-600 hover:text-red-700" />
              </Link>
            </div>
          </div>
          <div className="hidden lg:block" />
          <div>
            {categories && categories.length > 0 ? (
              <>
                <Text size="lg" className="font-semibold">
                  Nos produits
                </Text>
                <nav
                  aria-label="Catégories de produits"
                  className="mt-2 flex flex-col items-start gap-0.5"
                >
                  {categories.map((category) => (
                    <Link
                      key={category.id}
                      to={`/${category.slug}`}
                      className="py-0.5 text-sm hover:underline"
                    >
                      {category.title}
                    </Link>
                  ))}
                </nav>
              </>
            ) : null}
          </div>
          <div>
            <nav aria-label="Liens utiles" className="flex flex-col items-start gap-0.5">
              {contact ? (
                <Link to="/account/profile" className="py-0.5 text-sm hover:underline">
                  Votre espace
                </Link>
              ) : (
                <>
                  <Link to="/connexion" className="py-0.5 text-sm hover:underline">
                    Se connecter
                  </Link>
                  <Link to="/inscription" className="py-0.5 text-sm hover:underline">
                    Créer un compte
                  </Link>
                </>
              )}
              <Link
                to="https://eaf2ead2.sibforms.com/serve/MUIFAGkJUB3J6E-27nCE7WB5y-nm2MBdxPXwXTyvPqfp7bLg4E_bt8IqGOfAmaHJFxrbIrS43q9AR_CstFxEyruVYCqZSpoELobuGyt8ByhwLcasNfbt7tmkhoQ5M-xPvGuCJ6QqVSPS9AmyDHDGXws8WkeJhb4r37tVIxj4GsryPR9Ai6PQfKmZ1hHzxoWiikdn0-Un0bl7fC-L"
                target="_blank"
                rel="noopener noreferrer"
                className="py-0.5 text-sm hover:underline"
              >
                Newsletter
              </Link>
              <Link to="/nous-contacter" className="py-0.5 text-sm hover:underline">
                Nous contacter
              </Link>

              <Link to="/a-propos/qui-sommes-nous" className="mt-4 py-0.5 text-sm hover:underline">
                A propos
              </Link>
              <Link to="/glossaire/a" className="py-0.5 text-sm hover:underline">
                Glossaire
              </Link>
              <Link to="/faq/connexion" className="py-0.5 text-sm hover:underline">
                FAQ
              </Link>
              <Link to="/outils-et-services" className="py-0.5 text-sm hover:underline">
                Outils et services
              </Link>

              <Text size="lg" className="mt-4 font-semibold">
                Nos marques
              </Text>
              <Link
                to="/modules-photovoltaiques?marques=duonergy"
                className="mt-2 py-0.5 text-sm hover:underline"
              >
                Duonergy
              </Link>
              <Link
                to="/ombrieres-et-carports?marques=shape"
                className="py-0.5 text-sm hover:underline"
              >
                Shape
              </Link>
              <Link
                to="/kits-plug-and-play?marques=utoo"
                className="py-0.5 text-sm hover:underline"
              >
                Utoo
              </Link>
            </nav>
          </div>
        </Wrapper>
        <Wrapper>
          <nav
            aria-label="Autres liens"
            className="mt-8 flex flex-wrap items-center gap-y-2 divide-x divide-grey-400 text-grey-600"
          >
            <Link to="/a-propos/mentions-legales" className="pr-2 text-xs hover:underline">
              Mentions légales
            </Link>
            <Link
              to="/a-propos/conditions-generales-de-vente"
              className="px-2 text-xs hover:underline"
            >
              CGV
            </Link>
            <Link to="/plan-du-site" className="px-2 text-xs hover:underline">
              Plan du site
            </Link>

            <Link
              to="https://www.powr.group/"
              target="_blank"
              rel="noopener noreferrer"
              className="pl-2 text-xs hover:underline sm:ml-auto sm:border-none"
            >
              POwR Group
            </Link>
          </nav>
        </Wrapper>
      </PageContainer>
    </footer>
  );
}

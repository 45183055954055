import type { Product } from "~types/api/product.types";

import { Button } from "~/components/ui/button";
import {
  Modal,
  ModalButtons,
  ModalDescription,
  type ModalProps,
  ModalTitle,
} from "~/components/ui/modal";

interface PriceChangeWarningModalProps extends ModalProps {
  product: Product;
}

export function PriceWarningModal({ product, ...props }: PriceChangeWarningModalProps) {
  return (
    <Modal type="warning" size="md" {...props}>
      <ModalTitle>Prix susceptible d&apos;évoluer</ModalTitle>

      <ModalDescription>
        {product.stocks[0]?.etatStock === 3 ? (
          <>
            Le produit a été ajouté au panier, mais ce produit est en cours de réapprovisionnement,
            son prix est donc susceptible d&apos;évoluer.
          </>
        ) : product.stocks[0]?.etatStock === 4 ? (
          <>
            Le produit a été ajouté au panier, mais ce produit est sur commande, son prix est donc
            susceptible d&apos;évoluer.
          </>
        ) : null}
      </ModalDescription>

      <ModalButtons>
        <Button onClick={() => props.setOpen(false)}>Compris</Button>
      </ModalButtons>
    </Modal>
  );
}
